export enum ILanguage {
  enGB = 'en-GB',
  enUS = 'en-US',
  fr = 'fr',
  es = 'es',
  dev = 'dev',
}

export enum Locale {
  enGb = 'en-gb',
  en = 'en',
  fr = 'fr',
  es = 'es',
}

export const dotDecimalSeparatedLanguages: string[] = [
  ILanguage.enGB,
  ILanguage.enUS,
];
