import { Dispatch, createContext, useContext } from 'react';

import { GraphSetData, GraphSetDataActionType } from './types';

export const GraphSetDataContext = createContext<GraphSetData>([]);

export const GraphSetDataUpdateContext = createContext<
  Dispatch<GraphSetDataActionType>
>(() => {});

export const useGraphSetDataDispatch = () => {
  return useContext(GraphSetDataUpdateContext);
};

export const useGraphSetData = () => {
  return useContext(GraphSetDataContext);
};
