export enum GraphSetDataDispatchActions {
  UpdateValues = 'updateValues',
}

export type GraphSetDataActionType = {
  type: GraphSetDataDispatchActions.UpdateValues;
  values: GraphSetData;
};

export type GraphSetData = (({ ring: number } | { date: string }) &
  Record<string, string>)[];
