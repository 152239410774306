import { Form, Input } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { graphSetStateVersion } from 'business/data-analysis/constants';
import GraphSetSidebar from 'business/data-analysis/pages/graph/components/graph-set-side-bar';
import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import {
  GraphPageState,
  GraphSetDispatchActions,
} from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { SaveGraphSetForm } from 'business/data-analysis/pages/graph/types';
import { mapToStructureDtoFromGraphSetStructure } from 'business/data-analysis/services/map-to-structure-dto-from-graph-set-structure';
import { useDataAnalysisControllerSaveGraphSet } from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import Button from 'ui/button';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onCloseSaveSideBar: () => void;
  refetchGraphSetCount: () => void;
}

const SaveSidebar = ({
  visible,
  setVisible,
  onCloseSaveSideBar,
  refetchGraphSetCount,
}: Props) => {
  const { t } = useTranslation();
  const [submittable, setSubmittable] = useState(false);

  const { currentConstructionSiteId, user } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.DataAnalysis);

  invariant(user && currentConstructionSiteId, 'No construction site id');

  const graphSet = useGraphSet();
  const dispatchGraphSet = useGraphSetDispatch();

  const [form] = useForm<SaveGraphSetForm>();
  const values = useWatch([], form);

  // To reset the title field to the current graph set title if we load a saved graph set
  useEffect(() => {
    form.setFieldValue('title', graphSet.structure.title);
  }, [form, graphSet]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const { mutateAsync: saveGraphSet, isPending: isSavePending } =
    useDataAnalysisControllerSaveGraphSet({
      onSuccess: (data) => {
        const savedStructure: GraphPageState['structure'] = graphSet.structure;

        if (data?.id) {
          savedStructure.id = data.id;
          savedStructure.title = data.title;
        }

        dispatchGraphSet({
          type: GraphSetDispatchActions.LoadGraphSetStructure,
          structure: savedStructure,
        });

        refetchGraphSetCount();
        setVisible(false);
      },
    });

  const onSave = async ({ title }: SaveGraphSetForm) => {
    const newSavedGraphSetStructure: GraphPageState['structure'] = {
      ...graphSet.structure,
      title,
    };

    const serializedGraphSetStructure = mapToStructureDtoFromGraphSetStructure(
      newSavedGraphSetStructure,
    );

    await saveGraphSet({
      headers,
      body: {
        graphSetStructure: serializedGraphSetStructure,
        userId: user.id,
        constructionSiteId: currentConstructionSiteId,
        version: graphSetStateVersion,
      },
    });
  };

  return (
    <GraphSetSidebar
      title={t('dataAnalysis.saveSideBar.title')}
      visible={visible}
      onClose={onCloseSaveSideBar}
    >
      <Form
        form={form}
        onFinish={() => onSave(values)}
        layout="vertical"
        initialValues={{ title: graphSet.structure.title }}
      >
        <Form.Item
          label={t('dataAnalysis.saveSideBar.setTitle')}
          name="title"
          rules={[
            {
              required: true,
              message: t('dataAnalysis.saveSideBar.requiredTitle'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Flex className={styles.actionsContainer}>
          <Form.Item>
            <Button onClick={() => onCloseSaveSideBar()}>
              {t('dataAnalysis.saveSideBar.cancelAction')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isSavePending}
              htmlType="submit"
              disabled={!submittable}
              type="primary"
            >
              {t('dataAnalysis.saveSideBar.saveAction')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </GraphSetSidebar>
  );
};

export default SaveSidebar;
