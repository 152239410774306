import { Flex } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import config from 'config';
import AppLogo from 'ui/appLogo';

import styles from './index.module.scss';

interface ProdAndPerfPrintHeaderProps {
  printTitle: string;
}

export const ProdAndPerfPrintHeader = ({
  printTitle,
}: ProdAndPerfPrintHeaderProps) => {
  const { currentConstructionSite } = useAppContext();
  const { t } = useTranslation();
  const filters = useProdAndPerfFilters();

  const locale = getLocale();

  const { filterType, dayMode } = filters;
  let dateRange, ringRange;

  if (filterType === 'date') {
    dateRange = filters.dateRange;
  }
  if (filterType === 'ring') {
    ringRange = filters.ringRange;
  }

  const range = {
    date: t('productionAndPerformance.print.dateRange', {
      startDate: dayjs.tz(dateRange?.[0]).locale(locale).format('L'),
      endDate: dayjs.tz(dateRange?.[1]).locale(locale).format('L'),
    }),
    ring: t('productionAndPerformance.print.ringRange', {
      startRing: ringRange?.[0],
      endRing: ringRange?.[1],
    }),
  };

  const mode = {
    shift: t('productionAndPerformance.print.shift'),
    calendar: t('productionAndPerformance.print.calendar'),
  };

  return (
    <Flex vertical className={styles.header}>
      <Flex justify="center" gap={20}>
        <AppLogo />
        {currentConstructionSite?.logo ? (
          <AppLogo
            alt="Construction site logo"
            path={`${config.gcp.publicUri}/${currentConstructionSite.logo}`}
          />
        ) : null}
      </Flex>
      {currentConstructionSite?.name ? (
        <h1 className={styles.constructionSiteName}>
          {currentConstructionSite?.name}
        </h1>
      ) : null}
      <h1>{printTitle}</h1>
      <h3>
        {range[filterType]} - {t('productionAndPerformance.print.startOfDay')}
        {mode[dayMode]}
      </h3>
    </Flex>
  );
};
