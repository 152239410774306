import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export type LegendFilter = {
  realExcavationDuration: boolean;
  realBuildDuration: boolean;
  totalExcavationDuration: boolean;
  totalBuildDuration: boolean;
};

const defaultLegendFilter: LegendFilter = {
  realExcavationDuration: true,
  realBuildDuration: true,
  totalExcavationDuration: true,
  totalBuildDuration: true,
};

const LegendFilterContext = createContext<{
  legendFilter: LegendFilter;
  setLegendFilter: Dispatch<SetStateAction<LegendFilter>>;
}>({
  legendFilter: defaultLegendFilter,
  setLegendFilter: () => {},
});

const LegendFilterProvider = ({ children }: PropsWithChildren) => {
  const [legendFilter, setLegendFilter] =
    useState<LegendFilter>(defaultLegendFilter);

  return (
    <LegendFilterContext.Provider value={{ legendFilter, setLegendFilter }}>
      {children}
    </LegendFilterContext.Provider>
  );
};

const useLegendFilter = () => useContext(LegendFilterContext);

export { LegendFilterProvider, useLegendFilter };
