import { PropsWithChildren, useReducer } from 'react';

import { useMode } from './../use-mode';
import { getGraphSetContext, GraphSetUpdateContext } from './contexts';
import { getInitialGraphSetState } from './get-initial-graph-set';
import { createGraphSetReducer } from './reducer';

export const GraphSetProvider = ({ children }: PropsWithChildren) => {
  const mode = useMode();

  const graphSetContext = getGraphSetContext(mode);

  const graphSetReducer = createGraphSetReducer();
  const [graphSet, dispatchGraphSet] = useReducer(
    graphSetReducer,
    getInitialGraphSetState(mode),
  );

  return (
    <graphSetContext.Provider value={graphSet}>
      <GraphSetUpdateContext.Provider value={dispatchGraphSet}>
        {children}
      </GraphSetUpdateContext.Provider>
    </graphSetContext.Provider>
  );
};
