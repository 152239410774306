import { Radio, Form, ConfigProvider, Flex, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { usePermissions } from 'business/contextProviders/usePermissions';
import {
  useSteeringFilters,
  useSteeringFiltersFormName,
} from 'business/steering/providers/steering-filters-provider';
import { Position } from 'business/steering/providers/steering-filters-provider/types';
import { useGetCurrentRingQuery } from 'generated/graphql';
import { NumberRangeInputWithMessage } from 'ui/form/number-range-input-with-message';
import { SliderWithInputNumber } from 'ui/form/slider-with-input-number';
import TemporalitySelectInput, {
  DateSelectionTypeEnum,
} from 'ui/form/temporality-select-input';

export interface SteeringFiltersProps {
  toleranceEnabled?: boolean;
  scaleEnabled?: boolean;
  positionEnabled?: boolean;
  noDefaultFor?: DateSelectionTypeEnum[];
}

export const SteeringFilters = ({
  toleranceEnabled,
  scaleEnabled,
  positionEnabled,
  noDefaultFor,
}: SteeringFiltersProps) => {
  const { t } = useTranslation();
  const formName = useSteeringFiltersFormName();
  const filters = useSteeringFilters();
  const [form] = Form.useForm();

  const { currentConstructionSite } = useAppContext();

  const { constructionSiteId } = usePermissions();
  const { data, error } = useGetCurrentRingQuery({
    variables: { constructionSiteId },
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 0,
          },
        },
      }}
    >
      <Form
        layout={'inline'}
        form={form}
        name={formName}
        initialValues={filters}
      >
        <Form.Item
          layout={'vertical'}
          name="filterType"
          label={t('input.filterByOptions.label')}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={[
              {
                label: t('input.filterByOptions.date'),
                value: 'date',
              },
              {
                label: t('input.filterByOptions.ring'),
                value: 'ring',
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.filterType !== curValues.filterType
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const filterType = getFieldValue('filterType');

            if (filterType === 'date') {
              return (
                <Flex>
                  <Form.Item
                    name="dateRange"
                    layout={'vertical'}
                    label={t('input.dateRange.label')}
                  >
                    <TemporalitySelectInput
                      defaultDate={dayjs()}
                      noDefaultFor={noDefaultFor}
                    />
                  </Form.Item>
                </Flex>
              );
            } else if (filterType === 'ring') {
              return (
                <NumberRangeInputWithMessage
                  name="ringRange"
                  label={t('input.ringRange.label')}
                  leftPlaceholder={t('input.ringRange.startPlaceholder')}
                  rightPlaceholder={t('input.ringRange.endPlaceholder')}
                  message={
                    error
                      ? t('dataAnalysis.errors.currentRingNumberError')
                      : t('dataAnalysis.labels.currentRingNumber', {
                          ringNumber:
                            data?.parameter_ring_aggregate.aggregate?.max
                              ?.ringNumber,
                        })
                  }
                />
              );
            }
            return null;
          }}
        </Form.Item>

        {positionEnabled ? (
          <Form.Item
            name="position"
            layout="vertical"
            label={t('steering.filters.position.label')}
          >
            <Select<Position>
              popupMatchSelectWidth={false}
              options={
                [
                  {
                    label: t('steering.filters.position.rear'),
                    value: 'rear',
                  },
                  {
                    label: t('steering.filters.position.front'),
                    value: 'front',
                  },
                  ...(currentConstructionSite?.isArticulated
                    ? [
                        {
                          label: t('steering.filters.position.cuttingWheel'),
                          value: 'cuttingWheel',
                        } as const,
                      ]
                    : []),
                ] satisfies { label: string; value: Position }[]
              }
            />
          </Form.Item>
        ) : null}

        {toleranceEnabled ? (
          <Form.Item
            name="tolerance"
            layout="vertical"
            label={t('steering.filters.tolerance')}
          >
            <SliderWithInputNumber min={0} max={500} />
          </Form.Item>
        ) : null}

        {scaleEnabled ? (
          <Form.Item
            name="scale"
            layout="vertical"
            label={t('steering.filters.scale')}
          >
            <InputNumber min={0} />
          </Form.Item>
        ) : null}
      </Form>
    </ConfigProvider>
  );
};
