import { Tooltip } from 'antd';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';
import { i18n } from 'translations';

import { AnalysisType } from 'business/task/pages/TasksAnalyzer/types';
import {
  ActivityExtendedFragment,
  ActivityMinimalTreeFragment,
  GetRingAggregationTasksAnalyzerDataQuery,
  GetShiftReportTasksAnalyzerDataQuery,
  RingAggregationCriticalTaskEnum,
  Scalars,
  ShiftReportCriticalTaskEnum,
} from 'generated/graphql';
import { DateRangeType } from 'technical/types';
import { NumberRangeValue } from 'ui/form/number-range-input';
import LocationIcon from 'ui/icons/location';

interface ActivityChildren extends ActivityExtendedFragment {
  children?: (ActivityExtendedFragment & ActivityChildren)[];
}

function getActivityChildrenIds(children: ActivityChildren[]): string[] {
  return children.reduce((acc: string[], child) => {
    if (child.children?.length) {
      return [...acc, ...getActivityChildrenIds(child.children)];
    }
    return [...acc, child.id];
  }, [] as string[]);
}

export function checkAllActivities(activities?: ActivityMinimalTreeFragment[]) {
  return (
    activities?.reduce<string[]>((acc, activity) => {
      if (activity.children?.length) {
        return [
          ...acc,
          ...getActivityChildrenIds(activity.children as ActivityChildren[]),
        ];
      }
      return [...acc, activity.id];
    }, []) ?? []
  );
}

export interface ActivityChildrenToDisplay extends ActivityExtendedFragment {
  children?: (ActivityExtendedFragment & ActivityChildren)[];
  hiddenWithData?: boolean;
}

const isParentActivity = (activity: ActivityChildrenToDisplay) =>
  !!activity.children?.length;

const isHiddenActivity = (activityWithNoChildren: ActivityChildrenToDisplay) =>
  activityWithNoChildren.hidden;

const activityHasLinkedData = (activity: ActivityChildrenToDisplay) =>
  !!(activity.tasks_aggregate.nodes.length > 0);

const getActivityWithSpecialDisplay = (
  hiddenActivityWithLinkedData: ActivityChildrenToDisplay,
) => ({
  ...hiddenActivityWithLinkedData,
  hiddenWithData: true,
  icon: LocationIcon,
  title: (
    <Tooltip title={i18n.t('tasksAnalyzer.activityHiddenWithData')}>
      <span style={{ opacity: 0.6 }}>{hiddenActivityWithLinkedData.title}</span>
    </Tooltip>
  ) as unknown as string,
});

export const discardHiddenActivities = (
  activities: ActivityChildrenToDisplay[],
): ActivityChildrenToDisplay[] => {
  return activities.reduce<ActivityChildrenToDisplay[]>((acc, cur) => {
    if (isParentActivity(cur)) {
      const { children, ...parentActivityData } = cur;

      return [
        ...acc,
        {
          ...parentActivityData,
          // Children verified in isParentActivity()
          children: discardHiddenActivities(children!),
        },
      ];
    }

    // "Normal" activity - Nothing to do here
    if (!isHiddenActivity(cur)) {
      return [...acc, cur];
    }

    // Hidden activity with no linked data is discarded from activity tree
    if (!activityHasLinkedData(cur)) {
      return acc;
    }

    // Hidden activity with linked data is displayed but with a info tooltip
    if (activityHasLinkedData(cur)) {
      return [...acc, getActivityWithSpecialDisplay(cur)];
    }

    return acc;
  }, []);
};

export const dateRangeNotNull = (dateRangeValue: DateRangeType) =>
  Boolean(dateRangeValue && dateRangeValue[0] && dateRangeValue[1]);

export const inputIsValidNumber = (input?: number | null | undefined) =>
  typeof input !== 'undefined' && input !== null && input !== undefined;

export const ringRangeIsValidNumbers = (ringRangeValue: NumberRangeValue) =>
  Boolean(
    ringRangeValue &&
      inputIsValidNumber(ringRangeValue[0]) &&
      inputIsValidNumber(ringRangeValue[1]),
  );

export const ringRangeIsValidValue = (ringRangeValue: NumberRangeValue) =>
  Boolean(
    ringRangeValue &&
      inputIsValidNumber(ringRangeValue[0]) &&
      inputIsValidNumber(ringRangeValue[1]) &&
      // null | undefined check done above
      // @ts-ignore
      ringRangeValue[0] <= ringRangeValue[1],
  );

export const emptyChartData = ({
  analysisType,
  dateChartData,
  ringRangeValue,
  ringChartData,
  graphData,
}: {
  analysisType: AnalysisType;
  dateChartData?: GetShiftReportTasksAnalyzerDataQuery;
  ringRangeValue: NumberRangeValue;
  ringChartData?: GetRingAggregationTasksAnalyzerDataQuery;
  graphData?: any[][];
}): boolean => {
  if (analysisType === 'date') {
    return Boolean(
      !graphData ||
        (dateChartData && dateChartData.tasksAnalyzerData?.length === 0),
    );
  }
  return Boolean(
    !graphData ||
      (ringChartData &&
        ringChartData.tasksAnalyzerData?.length === 0 &&
        ringRangeIsValidValue(ringRangeValue)),
  );
};

export const skipDateRangeChartData = ({
  analysisType,
  activityList,
  constructionSiteId,
  dateRangeValue,
}: {
  analysisType: AnalysisType;
  activityList: string[];
  constructionSiteId: Scalars['uuid'] | null;
  dateRangeValue: DateRangeType;
}) => {
  if (!activityList.length) {
    return true;
  }

  return Boolean(
    analysisType !== 'date' ||
      !constructionSiteId ||
      !dateRangeNotNull(dateRangeValue),
  );
};

export const skipRingRangeChartData = ({
  analysisType,
  constructionSiteId,
  ringRangeValue,
}: {
  analysisType: AnalysisType;
  constructionSiteId: Scalars['uuid'] | null;
  ringRangeValue: NumberRangeValue;
}) =>
  Boolean(
    analysisType !== 'ring' ||
      !constructionSiteId ||
      !ringRangeIsValidValue(ringRangeValue),
  );

export const shouldDisplayTotalAvailability = ({
  isChartDataEmpty,
  analysisType,
  chartType,
  dateCriticalType,
  ringCriticalType,
}: {
  isChartDataEmpty: boolean;
  analysisType: AnalysisType;
  chartType: GoogleChartWrapperChartType;
  dateCriticalType: ShiftReportCriticalTaskEnum;
  ringCriticalType: RingAggregationCriticalTaskEnum;
}) => {
  if (isChartDataEmpty || chartType !== 'PieChart') {
    return false;
  }
  if (
    analysisType === 'date' &&
    dateCriticalType === ShiftReportCriticalTaskEnum.Critical
  ) {
    return true;
  }
  if (
    analysisType === 'ring' &&
    ringCriticalType === RingAggregationCriticalTaskEnum.Critical
  ) {
    return true;
  }
  return false;
};
