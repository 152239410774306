import { GraphPageState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';

export const loadGraphSetStructure = (
  graphSet: GraphPageState,
  structure: GraphPageState['structure'],
): GraphPageState => {
  return {
    ...graphSet,
    currentGraphId: null,
    structure,
    savedState: structuredClone(structure),
  };
};
