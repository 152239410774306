import { Flex, Form, Typography } from 'antd';

import { validateRingRangeRule } from 'technical/validation/rules';
import NumberRangeInput from 'ui/form/number-range-input';

import styles from './index.module.scss';

export const NumberRangeInputWithMessage = ({
  name,
  label,
  leftPlaceholder,
  rightPlaceholder,
  message,
}: {
  name: string;
  label: string;
  leftPlaceholder: string;
  rightPlaceholder: string;
  message?: string;
}) => {
  return (
    <Flex vertical>
      <Form.Item
        name={name}
        layout="vertical"
        label={label}
        validateTrigger="onChange"
        rules={[validateRingRangeRule]}
      >
        <NumberRangeInput
          leftInputProps={{
            min: 0,
            placeholder: leftPlaceholder,
          }}
          rightInputProps={{
            min: 0,
            placeholder: rightPlaceholder,
          }}
        />
      </Form.Item>
      {message ? (
        <Typography.Text
          italic
          type="secondary"
          className={styles.currentRingNumber}
        >
          {message}
        </Typography.Text>
      ) : null}
    </Flex>
  );
};
