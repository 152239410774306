import { LinkOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import { useGraphSetDispatch } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraphSetDataDispatch } from 'business/data-analysis/pages/graph/hooks/graph-data-context/contexts';
import { GraphSetDataDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-data-context/types';
import Routes from 'config/routes';

const PlotterModeTranslationKeys = {
  [PlotterMode.TIME_PERIOD]: 'dataAnalysis.modeSelection.timePeriod',
  [PlotterMode.RING]: 'dataAnalysis.modeSelection.ring',
};

export const ChangeModeButton = ({
  mode,
  needSave,
}: {
  mode: PlotterMode;
  needSave: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatchGraphSet = useGraphSetDispatch();
  const dispatchGraphSetData = useGraphSetDataDispatch();

  const toggledMode =
    mode === PlotterMode.RING ? PlotterMode.TIME_PERIOD : PlotterMode.RING;

  const resetGraphSet = () => {
    dispatchGraphSetData({
      type: GraphSetDataDispatchActions.UpdateValues,
      values: [],
    });
    dispatchGraphSet({
      type: GraphSetDispatchActions.ResetGraphSet,
      mode: toggledMode,
    });
  };

  const handleConfirm = () => {
    resetGraphSet();
    navigate(generatePath(Routes.DataAnalysisGraph, { mode: toggledMode }));
  };

  return needSave ? (
    <Popconfirm
      title={t('dataAnalysis.modeSelection.confirm.title')}
      description={t('dataAnalysis.modeSelection.confirm.description')}
      okText={t('dataAnalysis.modeSelection.confirm.ok')}
      cancelText={t('dataAnalysis.modeSelection.confirm.cancel')}
      onConfirm={handleConfirm}
      placement="bottom"
    >
      <Button icon={<LinkOutlined />} iconPosition="end">
        {t(`${PlotterModeTranslationKeys[toggledMode]}.title`)}
      </Button>
    </Popconfirm>
  ) : (
    <Link to={generatePath(Routes.DataAnalysisGraph, { mode: toggledMode })}>
      <Button
        onClick={resetGraphSet}
        icon={<LinkOutlined />}
        iconPosition="end"
      >
        {t(`${PlotterModeTranslationKeys[toggledMode]}.title`)}
      </Button>
    </Link>
  );
};
