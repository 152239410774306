import { getNewGraphNumber } from 'business/data-analysis/services/get-new-graph-number';

import { createEmptyGraph } from './../create-empty-graph';
import { GraphPageState } from './../types';

export const addGraph = (graphSet: GraphPageState): GraphPageState => {
  const graphs = graphSet.structure.graphs;

  const graphNumber = getNewGraphNumber(Array.from(graphs.values()));

  const { id, newGraph } = createEmptyGraph(graphNumber);
  graphs.set(id, newGraph);

  const res = {
    ...graphSet,
    structure: {
      ...graphSet.structure,
      graphs,
    },
  } satisfies GraphPageState;

  return res;
};
