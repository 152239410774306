import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { PlotterMode } from 'business/data-analysis/constants';
import { NumberRangeValue } from 'ui/form/number-range-input';

import { OnChangeGraphFilters } from './types';

export type UseFilters = (mode: PlotterMode) => {
  filters: {
    dateFilters: {
      startDate: Dayjs;
      endDate: Dayjs;
    };
    ringFilters: NumberRangeValue;
  };
  onChangeFilters: {
    onChangeDateRange: RangePickerProps['onChange'];
    onChangeRingRange: OnChangeGraphFilters['onChangeRingRange'];
  };
};

export const useFilters: UseFilters = (mode: PlotterMode) => {
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(1, 'day').startOf('day'),
  );
  const [endDate, setEndDate] = useState<Dayjs>(
    dayjs().subtract(1, 'day').endOf('day'),
  );

  const [ringRange, setRingRange] = useState<NumberRangeValue>([null, null]);

  const onChangeDateRange: RangePickerProps['onChange'] = (
    dates,
    _dateStrings,
  ) => {
    if (dates?.[0]) {
      setStartDate(dates[0]);
    }
    if (dates?.[1]) {
      setEndDate(dates[1]);
    }
  };

  const onChangeRingRange: OnChangeGraphFilters['onChangeRingRange'] = (
    values: NumberRangeValue,
  ) => {
    setRingRange(values);
  };

  const dateFilters = { startDate, endDate };
  const ringFilters = ringRange;

  // Reset filter on mode change
  useEffect(() => {
    if (mode === PlotterMode.RING) {
      setStartDate(dayjs().subtract(1, 'day').startOf('day'));
      setEndDate(dayjs().subtract(1, 'day').endOf('day'));
    } else if (PlotterMode.TIME_PERIOD) {
      setRingRange([null, null]);
    }
  }, [mode]);

  return {
    filters: { dateFilters, ringFilters },
    onChangeFilters: { onChangeDateRange, onChangeRingRange },
  };
};
