import { Row, Col, Statistic } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useLegendFilter } from 'business/production-and-performance/pages/excavation-build-time/providers/legend-filter';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { parseAndFormatRingDuration } from 'business/production-and-performance/services/ring-duration';
import { GetExcavationAndBuildingDurationResponseDto } from 'generated/apiSchemas';

import styles from './index.module.scss';

interface Props {
  data: GetExcavationAndBuildingDurationResponseDto['averages'];
}

export const ExcavationBuildTimeAverages = ({ data }: Props) => {
  const { t } = useTranslation();

  const {
    legendFilter: {
      realExcavationDuration,
      realBuildDuration,
      totalExcavationDuration,
      totalBuildDuration,
    },
  } = useLegendFilter();

  const filters = useProdAndPerfFilters();
  const showTotalDurations = filters?.showTotalDurations;

  return (
    <Row className={styles.row} gutter={8}>
      <Col span={6}>
        {realExcavationDuration ? (
          <Statistic
            title={t(
              'productionAndPerformance.excavationBuildTime.averages.avgRealExcavationDuration',
            )}
            value={parseAndFormatRingDuration(data.avgRealExcavationDuration)}
          />
        ) : null}
      </Col>
      <Col span={6}>
        {realBuildDuration ? (
          <Statistic
            title={t(
              'productionAndPerformance.excavationBuildTime.averages.avgRealBuildDuration',
            )}
            value={parseAndFormatRingDuration(data.avgRealBuildDuration)}
          />
        ) : null}
      </Col>
      {showTotalDurations ? (
        <>
          <Col span={6}>
            {totalExcavationDuration ? (
              <Statistic
                title={t(
                  'productionAndPerformance.excavationBuildTime.averages.avgTotalExcavationDuration',
                )}
                value={parseAndFormatRingDuration(
                  data.avgTotalExcavationDuration,
                )}
              />
            ) : null}
          </Col>
          <Col span={6}>
            {totalBuildDuration ? (
              <Statistic
                title={t(
                  'productionAndPerformance.excavationBuildTime.averages.avgTotalBuildDuration',
                )}
                value={parseAndFormatRingDuration(data.avgTotalBuildDuration)}
              />
            ) : null}
          </Col>
        </>
      ) : null}
    </Row>
  );
};
