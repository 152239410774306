import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import { ProdAndPerfPrintHeader } from 'business/production-and-performance/components/prod-and-perf-print-title';
import moduleStyles from 'business/production-and-performance/production-and-performance.module.scss';
import { SimpleCard } from 'ui/SimpleCard';

import styles from './index.module.scss';

interface Props {
  title: string;
  header?: ReactNode;
  filters?: ReactNode;
  printTitle?: string;
}

export const ProdPerfPageTemplate = ({
  title,
  children,
  header,
  filters,
  printTitle,
}: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(moduleStyles.page, 'page-appear')}>
      <Flex
        align="stretch"
        justify="center"
        className={moduleStyles.container}
        vertical
        gap={20}
      >
        <ProdAndPerfPrintHeader printTitle={printTitle ?? ''} />

        <Flex justify="space-between" className={styles.header}>
          <Typography>{title}</Typography>
          {header}
        </Flex>

        <div className={styles.header}>{filters}</div>

        <SimpleCard>{children}</SimpleCard>
      </Flex>
    </div>
  );
};
