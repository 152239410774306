import { useApiContext } from 'generated/apiContext';
import { Module_Enum } from 'generated/graphql';
import { HASURA_ROLE_KEY } from 'technical/auth/hasura/constants';

import { usePermissions } from './usePermissions';

// This code is required because we cannot override a single header
// for endpoint generated by @openapi-codegen
// Wait for this PR https://github.com/fabien0102/openapi-codegen/pull/227
// When this one is merged, it will be possible to only override x-hasura-role
export const useRestApiHeaders = (module: Module_Enum) => {
  const { modules } = usePermissions();
  const { fetcherOptions } = useApiContext();

  const role = modules[module]?.role;

  if (role) {
    return {
      ...fetcherOptions.headers,
      [HASURA_ROLE_KEY]: role,
    };
  }

  return fetcherOptions.headers;
};

export const useGraphqlApiHeaders = (module: Module_Enum) => {
  const { modules } = usePermissions();

  const role = modules[module]?.role;

  if (role) {
    return {
      [HASURA_ROLE_KEY]: role,
    };
  }

  return {};
};

/**
 * Enforce user role for dashboard calls
 * The check that user has access to any other module than
 * SHIFT_REPORT is made by hasura or custom-server
 */
export const useRestApiHeadersForDashboard = () => {
  const { isDataManager } = usePermissions();
  const { fetcherOptions } = useApiContext();

  if (isDataManager) {
    return {
      ...fetcherOptions.headers,
      [HASURA_ROLE_KEY]: 'data-manager',
    };
  }

  return {
    ...fetcherOptions.headers,
    [HASURA_ROLE_KEY]: 'user',
  };
};

export const useGraphqlApiHeadersForDashboard = () => {
  const { isDataManager } = usePermissions();

  if (isDataManager) {
    return {
      [HASURA_ROLE_KEY]: 'data-manager',
    };
  }

  return {
    [HASURA_ROLE_KEY]: 'user',
  };
};
