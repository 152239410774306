import { PrinterOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useGraphReferenceContext } from 'business/production-and-performance/pages/excavation-build-time/providers/graph-reference';
import Button from 'ui/button';

import { ExcavationBuildTimeExport } from './excavation-build-time-export';

export const ExcavationBuildTimeHeader = () => {
  const { t } = useTranslation();

  const { ref } = useGraphReferenceContext();

  const handlePrint = async () => {
    const chartElement = ref?.current?.getOptions().container;

    await ref?.current?.updateDelta({
      width: 980,
    });

    window.print();

    await ref?.current?.updateDelta({
      width: chartElement?.clientWidth,
    });
  };

  return (
    <Flex gap={4}>
      <Button onClick={handlePrint} icon={<PrinterOutlined />}>
        {t('common.print')}
      </Button>
      <ExcavationBuildTimeExport />
    </Flex>
  );
};
