import { ExportOutlined } from '@ant-design/icons';
import { App } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import config from 'config';
import {
  useRingListControllerGenerateRingListByDateExport,
  useRingListControllerGenerateRingListByRingExport,
} from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';

export const RingTableHeader = () => {
  const { t } = useTranslation();
  const filters = useProdAndPerfFilters();
  const { message } = App.useApp();

  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { mutate: generateExportPerDate } =
    useRingListControllerGenerateRingListByDateExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        message.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const { mutate: generateExportPerRing } =
    useRingListControllerGenerateRingListByRingExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        message.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const onClick = () => {
    if (filters.filterType === 'date') {
      const [startDate, endDate] = filters.dateRange ?? [null, null];
      generateExportPerDate({
        body: {
          constructionSiteId: currentConstructionSiteId,
          // start and end date should not be null as we force to always have values, but input type say it can be null
          startDate: startDate?.format(SIMPLE_DATE_FORMAT),
          endDate: endDate?.format(SIMPLE_DATE_FORMAT),
          dayMode: 'calendar',
          locale: getLocale(),
        },
        headers,
      });
    }

    if (filters.filterType === 'ring') {
      const [startRing, endRing] = filters.ringRange ?? [null, null];
      generateExportPerRing({
        body: {
          constructionSiteId: currentConstructionSiteId,
          // start and end date should not be null as we force to always have values, but input type say it can be null
          startRing: startRing ?? undefined,
          endRing: endRing ?? undefined,
          locale: getLocale(),
        },
        headers,
      });
    }
  };

  return (
    <Button icon={<ExportOutlined />} iconPosition="end" onClick={onClick}>
      {t('common.export')}
    </Button>
  );
};
