import { message, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { RingsTable } from 'business/production-and-performance/pages/ring-table/components/ring-table';
import {
  TableParamsProps,
  useGetFilteredAndPaginatedRingsQuery,
} from 'business/production-and-performance/pages/ring-table/components/ring-table-container/hooks/use-get-filtered-and-paginated-rings-query';
import { UpdateRingModal } from 'business/production-and-performance/pages/ring-table/components/update-ring-modal';
import {
  RingTableColumnKeys,
  RingTableRow,
} from 'business/production-and-performance/pages/ring-table/types';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { GetRingsQuery } from 'generated/graphql';

const defaultCurrentPage = 1;
const defaultPageSize = 50;

const defaultOrderBy = {
  sortOrder: 'descend',
  sortField: RingTableColumnKeys.RingNumber,
} as const;

export const RingsTableContainer: React.FC = () => {
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  const filters = useProdAndPerfFilters();
  invariant(constructionSiteId, 'No constructionSite id');

  const [tableParams, setTableParams] = useState<
    TableParamsProps & {
      sortField: SorterResult<RingTableRow>['field'];
      sortOrder: SorterResult<RingTableRow>['order'];
    }
  >({
    current: defaultCurrentPage,
    pageSize: defaultPageSize,
    ...defaultOrderBy,
  });

  const [currentRecord, setCurrentRecord] = useState<
    GetRingsQuery['parameter_ring'][number] | null
  >(null);
  const isModalVisible = currentRecord !== null;

  const { data, loading, error } = useGetFilteredAndPaginatedRingsQuery(
    constructionSiteId,
    tableParams,
    filters,
  );

  const handleChange = (
    newPagination: TablePaginationConfig,
    _: unknown,
    sorter: SorterResult<RingTableRow> | SorterResult<RingTableRow>[],
  ) => {
    setTableParams({
      current: defaultCurrentPage,
      pageSize: defaultPageSize,
      ...newPagination,
      sortOrder: Array.isArray(sorter)
        ? defaultOrderBy.sortOrder
        : sorter.order,
      sortField: Array.isArray(sorter)
        ? defaultOrderBy.sortField
        : sorter.field,
    });
  };

  const openEditModal = (id: string | null) => {
    const ring = data?.parameter_ring.find((r) => r.id === id) || null;
    setCurrentRecord(ring);
  };

  const closeEditModal = () => {
    setCurrentRecord(null);
  };

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  return (
    <>
      <RingsTable
        data={data?.parameter_ring}
        loading={loading}
        pagination={{
          ...tableParams,
          total: data?.parameter_ring_aggregate?.aggregate?.count,
        }}
        onChange={handleChange}
        onEditionClick={openEditModal}
      />
      <UpdateRingModal
        isModalVisible={isModalVisible}
        closeModal={closeEditModal}
        ring={currentRecord}
      />
    </>
  );
};
