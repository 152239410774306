import { PropsWithChildren, useReducer } from 'react';

import { graphSetDataReducer } from 'business/data-analysis/pages/graph/hooks/graph-data-context/reducer';

import { GraphSetDataContext, GraphSetDataUpdateContext } from './contexts';

export const GraphSetDataProvider = ({ children }: PropsWithChildren) => {
  const [graphSetData, dispatchGraphSetData] = useReducer(
    graphSetDataReducer,
    [],
  );

  return (
    <GraphSetDataContext.Provider value={graphSetData}>
      <GraphSetDataUpdateContext.Provider value={dispatchGraphSetData}>
        {children}
      </GraphSetDataUpdateContext.Provider>
    </GraphSetDataContext.Provider>
  );
};
