import { PlotterMode } from 'business/data-analysis/constants';
import { DisplayType } from 'business/data-analysis/pages/graph/types';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';

export enum GraphSetDispatchActions {
  AddGraph = 'addGraph',
  AddParameters = 'addParameters',
  DeleteGraph = 'deleteGraph',
  LoadGraphSetStructure = 'loadGraphSetStructure',
  RemoveParameters = 'removeParameters',
  RenameGraph = 'renameGraph',
  ResetGraphSet = 'resetGraphSet',
  SetCurrentGraphId = 'setCurrentGraphId',
  UpdateDisplayType = 'updateDisplayType',
  UpdateParameter = 'updateParameter',
  UpdateLoadingStatus = 'updateLoadingStatus',
}

export type GraphSetActionType =
  | {
      type: GraphSetDispatchActions.AddGraph;
    }
  | {
      type: GraphSetDispatchActions.AddParameters;
      graphId: string;
      parameters: { id: number; name: string; unit: string }[];
    }
  | { type: GraphSetDispatchActions.DeleteGraph; graphId: string }
  | {
      type: GraphSetDispatchActions.LoadGraphSetStructure;
      structure: GraphPageState['structure'];
      save?: boolean;
    }
  | {
      type: GraphSetDispatchActions.RemoveParameters;
      graphId: string;
      parameterUniqueIds: string[];
    }
  | {
      type: GraphSetDispatchActions.RenameGraph;
      graphId: string;
      title: string;
    }
  | { type: GraphSetDispatchActions.ResetGraphSet; mode: PlotterMode }
  | { type: GraphSetDispatchActions.SetCurrentGraphId; graphId: string | null }
  | {
      type: GraphSetDispatchActions.UpdateDisplayType;
      graphId: string;
      displayType: DisplayType;
    }
  | {
      type: GraphSetDispatchActions.UpdateParameter;
      graphId: string;
      parameterUniqueId: string;
      update: {
        color?: string;
        aggregationType?: string;
        computeType?: string;
      };
    }
  | {
      type: GraphSetDispatchActions.UpdateLoadingStatus;
      isLoading: boolean;
    };

export type PeriodParameter = {
  id: number;
  name: string;
  unit: string;
  color: string;
};

export type RingParameter = PeriodParameter & {
  aggregationType: 'avg' | 'max' | 'min' | 'last' | 'sum';
  computeType: 'excavation' | 'build' | 'excavation_and_build' | 'total';
};

export type GraphPageState = {
  currentGraphId: string | null;
  isLoading: boolean;
  structure: {
    id?: string | undefined;
    title: string;
    type: GraphSetTypeEnum_Enum;
    graphs: Map<
      string,
      {
        title: string;
        displayType: 'graph' | 'table';
        parameters: Map<string, PeriodParameter | RingParameter>;
        graphNumber: number;
      }
    >;
  };
  savedState: GraphPageState['structure'];
};

export type GraphState = Parameters<
  GraphPageState['structure']['graphs']['set']
>[1];
