import { useRestApiHeadersForDashboard } from 'business/contextProviders/useApiHeaders';
import {
  ProgressHistoryControllerProgressHistoryQueryParams,
  useDashboardProgressHistoryV1ControllerProgressHistory,
} from 'generated/apiComponents';

export const useProgressHistory = ({
  startDate,
  endDate,
  constructionSiteId,
}: ProgressHistoryControllerProgressHistoryQueryParams) => {
  const headers = useRestApiHeadersForDashboard();

  const {
    data: progressHistory,
    isFetching,
    error,
  } = useDashboardProgressHistoryV1ControllerProgressHistory({
    queryParams: {
      startDate,
      endDate,
      constructionSiteId,
    },
    headers,
  });

  return { error, loading: isFetching, progressHistory };
};
