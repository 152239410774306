import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import { ConstructionSitePlanningContainer } from 'business/production-and-performance/pages/planning-page/components/construction-site-planning';
import { PlanningHeader } from 'business/production-and-performance/pages/planning-page/components/construction-site-planning-header';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import { FilterCard } from 'ui/filter-card';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

const PlanningPage = () => {
  const { t } = useTranslation();

  const { currentConstructionSiteId } = useAppContext();

  if (isUndefinedOrNull(currentConstructionSiteId)) {
    return null;
  }

  return (
    <ProdAndPerfFiltersProvider
      initialFilters={{
        filterType: 'date',
        dateRange: [null, null],
        dayMode: 'shift',
        withProjection: true,
      }}
      noDefaultFor={[DateSelectionTypeEnum.Period]}
    >
      <ProdPerfPageTemplate
        title={t('productionAndPerformance.planning.title')}
        header={<PlanningHeader />}
        filters={
          <FilterCard>
            <ProdAndPerfFilters
              withProjectionEnabled
              noDefaultFor={[DateSelectionTypeEnum.Period]}
            />
          </FilterCard>
        }
      >
        <ConstructionSitePlanningContainer />
      </ProdPerfPageTemplate>
    </ProdAndPerfFiltersProvider>
  );
};

export default PlanningPage;
