import { Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import { RingTableRow } from 'business/production-and-performance/pages/ring-table/types';
import { GetRingsQuery } from 'generated/graphql';

import { useRingTableColumns } from './hooks/use-ring-table-columns';

interface RingsTableProps {
  data?: GetRingsQuery['parameter_ring'];
  loading: boolean;
  pagination: TablePaginationConfig;
  onChange: (
    pagination: TablePaginationConfig,
    _: unknown,
    sorter: SorterResult<RingTableRow> | SorterResult<RingTableRow>[],
  ) => void;
  onEditionClick: (id: string | null) => void;
}

export const RingsTable: React.FC<RingsTableProps> = ({
  data = [],
  loading,
  pagination,
  onChange,
  onEditionClick,
}) => {
  const columns = useRingTableColumns(onEditionClick);

  return (
    <Table
      bordered
      scroll={{ x: 'max-content' }}
      // 90 is the value of src/business/App.scss - $header-height
      sticky={{ offsetHeader: 90 }}
      columns={columns}
      size={'small'}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={onChange}
      rowKey={(record) => record.id}
    />
  );
};
