import { v4 as uuidV4 } from 'uuid';

import {
  mapPlotterModeToGraphSetType,
  PlotterMode,
} from 'business/data-analysis/constants';
import { displayTypes } from 'business/data-analysis/pages/graph/types';

import { GraphPageState } from './../types';

export const resetGraphSet = (mode: PlotterMode): GraphPageState => {
  const structure = {
    title: '',
    type: mapPlotterModeToGraphSetType[mode],
    graphs: new Map().set(uuidV4(), {
      title: '',
      displayType: displayTypes.graph,
      parameters: new Map(),
      graphNumber: 1,
    }),
  };

  return {
    currentGraphId: null,
    isLoading: false,
    structure,
    savedState: structuredClone(structure),
  };
};
