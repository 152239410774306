import { Flex } from 'antd';

import { useGraphqlApiHeadersForDashboard } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { AdvancementCard } from 'business/dashboard/components/advancement-card';
import { TbmMap } from 'business/dashboard/components/map';
import { TunnelBoringMachineHeader } from 'business/dashboard/components/tunnel-boring-machine-header';
import { useGetTbmInfosQuery } from 'generated/graphql';

import styles from './index.module.scss';

export const TunnelBoringMachineContainer = () => {
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  const headers = useGraphqlApiHeadersForDashboard();
  const tbmInfos = useGetTbmInfosQuery({
    variables: { constructionSiteId },
    context: {
      headers,
    },
  });

  return (
    <Flex vertical gap={20} className={styles.container}>
      <TunnelBoringMachineHeader tbmInfos={tbmInfos} />
      <AdvancementCard tbmInfos={tbmInfos} />
      <TbmMap tbmInfos={tbmInfos} />
    </Flex>
  );
};
