import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { TFunction, useTranslation } from 'translations/hooks';

import { getEmptyRingPerDayGraphPoint } from 'business/production-and-performance/pages/ring-per-day/services/getEmptyGraphPoint';
import { RingPerDayGraphPoint } from 'business/production-and-performance/pages/ring-per-day/types';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { formatGraphDateAxisLabel } from 'business/production-and-performance/services/graph-date-format';
import { addMissingDaysForGraphPerDay } from 'technical/graph/addMissingDaysForGraphPerDay';

type RingPerDayGraphProps = {
  ringPerDayGraphData: RingPerDayGraphPoint[];
};

const tooltipRenderer = (params: any, t: TFunction) => {
  return {
    data: [
      {
        label: '',
        value: t('productionAndPerformance.ringPerDay.graph.tooltip.ring', {
          count: parseInt(params.datum[params.yKey]),
        }),
      },
    ],
  };
};

const ringPerDayShiftGraphSeriesOptions = (t: TFunction) => {
  return [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift1Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 1,
      }),
      stacked: true,
      tooltip: { renderer: (params) => tooltipRenderer(params, t) },
    },
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift2Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 2,
      }),
      stacked: true,
      tooltip: { renderer: (params) => tooltipRenderer(params, t) },
    },
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift3Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 3,
      }),
      stacked: true,
      tooltip: { renderer: (params) => tooltipRenderer(params, t) },
    },
  ] satisfies AgCartesianSeriesOptions[];
};

const ringPerDayCalendarGraphSeriesOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'count',
      tooltip: {
        renderer: (params) => tooltipRenderer(params, t),
      },
    },
  ] satisfies AgCartesianSeriesOptions[];

const ringPerDayGraphAxesOptions = ({
  onSingleDay,
}: {
  onSingleDay: boolean;
}) =>
  [
    {
      type: 'time',
      position: 'bottom',
      // This should always be false to not have a day before and a day after
      // the interval. But it breaks the graph if where is only one day and
      // display nothing
      nice: onSingleDay ? true : false,
      label: {
        formatter: ({ value }) => formatGraphDateAxisLabel(value),
      },
    },
    {
      type: 'number',
      position: 'left',
      nice: false,
      interval: {
        step: 1,
      },
    },
  ] satisfies AgCartesianAxisOptions[];

export function RingPerDayGraph({ ringPerDayGraphData }: RingPerDayGraphProps) {
  const { t } = useTranslation();
  const filters = useProdAndPerfFilters();

  const ringPerDayGraphDataWithMissingDates = addMissingDaysForGraphPerDay(
    (filters.filterType === 'date' && filters.dateRange) || null,
    ringPerDayGraphData,
    getEmptyRingPerDayGraphPoint,
  );

  const chartOptions = {
    data: ringPerDayGraphDataWithMissingDates,
    series:
      filters.dayMode === 'shift'
        ? ringPerDayShiftGraphSeriesOptions(t)
        : ringPerDayCalendarGraphSeriesOptions(t),
    axes: ringPerDayGraphAxesOptions({
      onSingleDay: ringPerDayGraphData.length === 1,
    }),
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
}
