import { useGraphSetData } from 'business/data-analysis/pages/graph/hooks/graph-data-context/contexts';
import { useGraph } from 'business/data-analysis/pages/graph/hooks/use-graph';

export const useGraphHasValues = (graphId: string) => {
  const values = useGraphSetData();
  const graph = useGraph(graphId);

  const uniqueValues = values.reduce((prev, value) => {
    Object.keys(value).forEach((key) => {
      if (key !== 'date' && key !== 'ring') {
        prev.add(parseInt(key));
      }
    });
    return prev;
  }, new Set<number>());

  return Array.from(uniqueValues.keys()).some((key) =>
    Array.from(graph.parameters.values())
      .map((value) => value.id)
      .includes(key),
  );
};
