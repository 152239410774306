import { updateValues } from './action/update-values';
import {
  GraphSetData,
  GraphSetDataActionType,
  GraphSetDataDispatchActions,
} from './types';

export const graphSetDataReducer = (
  _graphSetData: GraphSetData,
  action: GraphSetDataActionType,
) => {
  switch (action.type) {
    case GraphSetDataDispatchActions.UpdateValues: {
      return updateValues(action.values);
    }
  }
};
