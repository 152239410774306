import { v4 as uuidV4 } from 'uuid';

import { displayTypes } from 'business/data-analysis/pages/graph/types';

import { GraphState } from './types';

export const createEmptyGraph = (
  graphNumber: number,
): { id: string } & {
  newGraph: GraphState;
} => {
  return {
    id: uuidV4(),
    newGraph: {
      title: '',
      displayType: displayTypes.graph,
      parameters: new Map(),
      graphNumber,
    },
  };
};
