import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { Module_Enum } from 'generated/graphql';

import { useRingPerDayExport } from './use-ring-per-day-export';

export const RingPerDayExport = () => {
  const { t } = useTranslation();
  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);
  invariant(currentConstructionSiteId, 'No constructionSite id');
  const { dayMode } = useProdAndPerfFilters();

  const { generateExport, fetchRange } = useRingPerDayExport();

  const onClick = () => {
    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: dayMode,
        locale: getLocale(),
        ...fetchRange,
      },
      headers,
    });
  };

  return (
    <Button icon={<ExportOutlined />} iconPosition="end" onClick={onClick}>
      {t('common.export')}
    </Button>
  );
};
