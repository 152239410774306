import { v4 as uuidV4 } from 'uuid';

import { PlotterMode } from 'business/data-analysis/constants';
import { displayTypes } from 'business/data-analysis/pages/graph/types';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';

import {
  GraphState,
  PeriodParameter,
  GraphPageState,
  RingParameter,
} from './types';

// TODO: add a translationKey to use if title is undefined

// Period
const initialPeriodGraphSetStructure = {
  title: '',
  type: GraphSetTypeEnum_Enum.Period,
  graphs: new Map<string, GraphState>().set(uuidV4(), {
    title: '',
    displayType: displayTypes.graph,
    parameters: new Map<string, PeriodParameter>(),
    graphNumber: 1,
  }),
};

const initialPeriodGraphSet: GraphPageState = {
  currentGraphId: null,
  isLoading: false,
  structure: initialPeriodGraphSetStructure,
  savedState: structuredClone(initialPeriodGraphSetStructure),
} satisfies GraphPageState;

// Ring
const initialRingGraphSetStructure = {
  title: '',
  type: GraphSetTypeEnum_Enum.Ring,
  graphs: new Map<string, GraphState>().set(uuidV4(), {
    title: '',
    displayType: displayTypes.graph,
    parameters: new Map<string, RingParameter>(),
    graphNumber: 1,
  }),
};

const initialRingGraphSet: GraphPageState = {
  currentGraphId: null,
  isLoading: false,
  structure: initialRingGraphSetStructure,
  savedState: structuredClone(initialRingGraphSetStructure),
} satisfies GraphPageState;

export const getInitialGraphSetState = (mode: PlotterMode) => {
  if (mode === PlotterMode.TIME_PERIOD) {
    return initialPeriodGraphSet;
  }
  return initialRingGraphSet;
};
