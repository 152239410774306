import { App, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import { useGraphqlApiHeaders } from 'business/contextProviders/useApiHeaders';
import { SteeringDataFields } from 'business/steering/pages/graph-page/types';
import { useSteeringFilters } from 'business/steering/providers/steering-filters-provider';
import {
  Module_Enum,
  Parameter_Ring_Bool_Exp,
  useGetSteeringDataQuery,
} from 'generated/graphql';
import { SORT_ORDER } from 'technical/graphql/sort-order';
import { validateRingRange } from 'technical/validation/rules';

export type RingTableParamsProps = Pick<
  TablePaginationConfig,
  'current' | 'pageSize'
> & {
  sortField: SorterResult<SteeringDataFields>['field'];
  sortOrder: SorterResult<SteeringDataFields>['order'];
};

export const useGetDataQuery = (
  constructionSiteId: string,
  tableParams?: RingTableParamsProps,
) => {
  const { message } = App.useApp();
  const filters = useSteeringFilters();
  const headers = useGraphqlApiHeaders(Module_Enum.Steering);

  const combinedFilters: Parameter_Ring_Bool_Exp = {};

  if (
    filters.filterType === 'ring' &&
    filters.ringRange &&
    validateRingRange(filters.ringRange)
  ) {
    const [min, max] = filters.ringRange;
    combinedFilters.ringNumber = {
      _gte: min === null ? undefined : min,
      _lte: max === null ? undefined : max,
    };
  }

  if (filters.filterType === 'date' && filters.dateRange) {
    const [min, max] = filters.dateRange;
    combinedFilters.buildEnd = {
      _gte: min === null ? undefined : min.toISOString(),
      _lte: max === null ? undefined : max.toISOString(),
    };
  }

  let limit = undefined;
  let offset = undefined;
  let orderBy = undefined;

  if (tableParams) {
    limit = tableParams.pageSize;
    offset =
      tableParams.current && tableParams.pageSize
        ? (tableParams.current - 1) * tableParams.pageSize
        : undefined;

    orderBy =
      tableParams.sortField && tableParams.sortOrder
        ? {
            [tableParams.sortField.toString()]:
              SORT_ORDER[tableParams.sortOrder],
          }
        : undefined;
  }

  const { data, loading } = useGetSteeringDataQuery({
    variables: {
      constructionSiteId,
      limit,
      offset,
      filters: combinedFilters,
      orderBy,
    },
    context: {
      headers,
    },
    onError: (error) => {
      message.open({
        type: 'error',
        content: error.message,
      });
    },
  });

  return { data, loading };
};
