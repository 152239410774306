import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';

import { ShiftReportGroupByEnum } from 'generated/graphql';
import { DateRangeType } from 'technical/types';
import { NumberRangeValue } from 'ui/form/number-range-input';

export type FilterType = 'date' | 'ring';
export type AnalysisType = 'day' | 'week' | 'month' | 'report' | 'ring';

export interface IndicatorAnalyzerOptions {
  filterBy: FilterType;
  dateRange: DateRangeType;
  ringRange: NumberRangeValue;
  groupBy: ShiftReportGroupByEnum;
  chartType: GoogleChartWrapperChartType;
}

export const CHART_TYPES: {
  type: GoogleChartWrapperChartType;
  label: string;
}[] = [
  { type: 'ColumnChart', label: 'indicators.chartOptions.ColumnChart' },
  { type: 'LineChart', label: 'indicators.chartOptions.LineChart' },
  { type: 'Table', label: 'indicators.chartOptions.Table' },
];
