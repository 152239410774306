import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import { App, Popconfirm, Table, TableProps } from 'antd';
import React from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useGraphqlApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { mapPlotterModeToGraphSetType } from 'business/data-analysis/constants';
import {
  useGraphSet,
  useGraphSetDispatch,
} from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { GraphSetDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-context/types';
import { useGraphSetDataDispatch } from 'business/data-analysis/pages/graph/hooks/graph-data-context/contexts';
import { GraphSetDataDispatchActions } from 'business/data-analysis/pages/graph/hooks/graph-data-context/types';
import { useMode } from 'business/data-analysis/pages/graph/hooks/use-mode';
import { mapToGraphSetFromStructureDto } from 'business/data-analysis/services/map-to-graph-set-from-structure-dto';
import {
  Exact,
  GetGraphSetListQuery,
  GetSavedGraphCountQuery,
  GraphSetTypeEnum_Enum,
  Module_Enum,
  useDeleteSavedGraphSetMutation,
  useGetGraphSetLazyQuery,
  useGetGraphSetListQuery,
} from 'generated/graphql';
import Button from 'ui/button';
import Flex from 'ui/flex';

import { GraphSetTag } from './components/graph-set-tag';
import styles from './index.module.scss';

interface GraphSetListProps {
  onClose: () => void;
  refetchGraphSetCount: (
    variables?:
      | Partial<
          Exact<{
            constructionSiteId: any;
            userId: any;
            type: GraphSetTypeEnum_Enum;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetSavedGraphCountQuery>>;
}

export const GraphSetList: React.FC<GraphSetListProps> = ({
  onClose,
  refetchGraphSetCount,
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { currentConstructionSiteId, user } = useAppContext();
  const headers = useGraphqlApiHeaders(Module_Enum.DataAnalysis);

  invariant(user && currentConstructionSiteId, 'No construction site id');

  const mode = useMode();
  const { structure } = useGraphSet();
  const dispatchGraphSet = useGraphSetDispatch();
  const dispatchGraphSetData = useGraphSetDataDispatch();

  const { data: graphSetList, refetch: graphSetListRefetch } =
    useGetGraphSetListQuery({
      fetchPolicy: 'network-only',
      variables: {
        constructionSiteId: currentConstructionSiteId,
        userId: user?.id,
        type: mapPlotterModeToGraphSetType[mode],
      },
      context: {
        headers,
      },
    });

  const [loadGraphSet] = useGetGraphSetLazyQuery({
    fetchPolicy: 'cache-and-network',
    context: {
      headers,
    },
  });

  const [deleteSavedGraphSetMutation] = useDeleteSavedGraphSetMutation({
    context: {
      headers,
    },
  });

  const onLoadGraphSet = async (graphSetId: string) => {
    // LazyQuery exec func does not throw but only return error property
    // https://github.com/apollographql/apollo-client/issues/11399
    const { data, error } = await loadGraphSet({
      variables: { id: graphSetId },
    });

    if (error || !data?.graphSet_by_pk) {
      message.warning(t('errors.error_generic'));
      return;
    }

    const loadedStructure = mapToGraphSetFromStructureDto(data.graphSet_by_pk);
    dispatchGraphSet({
      type: GraphSetDispatchActions.LoadGraphSetStructure,
      structure: loadedStructure,
    });

    onClose();
  };

  const deleteGraphSet = async (id: string) => {
    await deleteSavedGraphSetMutation({
      variables: { id },
      onCompleted: async () => {
        await graphSetListRefetch();
        await refetchGraphSetCount();
        if (id === structure.id) {
          dispatchGraphSetData({
            type: GraphSetDataDispatchActions.UpdateValues,
            values: [],
          });
          dispatchGraphSet({
            type: GraphSetDispatchActions.ResetGraphSet,
            mode,
          });
        }
      },
      onError: () => {
        message.open({
          type: 'error',
          content: t('dataAnalysis.errors.graphSetDeletion'),
        });
      },
    });
  };

  const columns: TableProps<
    GetGraphSetListQuery['graphSet'][number]
  >['columns'] = [
    {
      key: 'title',
      dataIndex: 'title',
    },
    {
      key: 'type',
      dataIndex: 'type',
      render: (graphSetType) => <GraphSetTag type={graphSetType} />,
    },
    {
      key: 'current',
      render: (_, record) =>
        structure.id === record.id ? <EyeOutlined /> : null,
    },
    {
      key: 'delete',
      align: 'right',
      render: (_, record) => (
        <Popconfirm
          onConfirm={async (event) => {
            event?.stopPropagation();
            await deleteGraphSet(record.id);
          }}
          onCancel={(event) => event?.stopPropagation()}
          title={t('dataAnalysis.myGraphSets.deleteAction.title')}
          okText={t('dataAnalysis.myGraphSets.deleteAction.confirm')}
          cancelText={t('dataAnalysis.myGraphSets.deleteAction.cancel')}
          placement="topLeft"
        >
          <Button
            type="text"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(event) => event.stopPropagation()}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Flex column className={styles.container}>
      <Table
        columns={columns}
        dataSource={graphSetList?.graphSet}
        showHeader={false}
        pagination={false}
        className={styles.list}
        rowKey={(record) => record.id}
        onRow={(record) => {
          return { onClick: () => onLoadGraphSet(record.id) };
        }}
      />

      <Flex justify={'flex-end'}>
        <Button type={'default'} size={'large'} onClick={onClose}>
          {t('common.close')}
        </Button>
      </Flex>
    </Flex>
  );
};
