import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useState } from 'react';
import invariant from 'tiny-invariant';

const defaultCurrentPage = 1;
const defaultPageSize = 50;

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  RingTableParamsProps,
  useGetDataQuery,
} from 'business/steering/hooks/use-get-steering-data-query';
import { SteeringDataFields } from 'business/steering/pages/graph-page/types';
import { SteeringTable } from 'business/steering/pages/table-page/components/steering-table';
import { SteeringTableColumnKeys } from 'business/steering/pages/table-page/components/steering-table/types';

const defaultOrderBy = {
  sortOrder: 'descend',
  sortField: SteeringTableColumnKeys.ringNumber,
} as const;

export const SteeringTableContainer = () => {
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  invariant(constructionSiteId, 'No construction site id');

  const [tableParams, setTableParams] = useState<RingTableParamsProps>({
    current: defaultCurrentPage,
    pageSize: defaultPageSize,
    ...defaultOrderBy,
  });

  const handleChange = (
    newPagination: TablePaginationConfig,
    _: any,
    sorter:
      | SorterResult<SteeringDataFields>
      | SorterResult<SteeringDataFields>[],
  ) => {
    setTableParams({
      current: defaultCurrentPage,
      pageSize: defaultPageSize,
      ...newPagination,
      sortOrder: Array.isArray(sorter)
        ? defaultOrderBy.sortOrder
        : sorter.order,
      sortField: Array.isArray(sorter)
        ? defaultOrderBy.sortField
        : sorter.field,
    });
  };

  const { data, loading } = useGetDataQuery(constructionSiteId, tableParams);

  return (
    <SteeringTable
      data={data?.parameter_ring}
      loading={loading}
      pagination={{
        ...tableParams,
        total: data?.parameter_ring_aggregate.aggregate?.count,
      }}
      onChange={handleChange}
    />
  );
};
