import {
  AgBarSeriesTooltipRendererParams,
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
  AgSeriesVisibilityChange,
  AgTooltipRendererResult,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { useEffect } from 'react';
import { TFunction, useTranslation } from 'translations/hooks';

import { useGraphReferenceContext } from 'business/production-and-performance/pages/excavation-build-time/providers/graph-reference';
import {
  LegendFilter,
  useLegendFilter,
} from 'business/production-and-performance/pages/excavation-build-time/providers/legend-filter';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { parseAndFormatRingDuration } from 'business/production-and-performance/services/ring-duration';
import { GetExcavationAndBuildingDurationResponseDto } from 'generated/apiSchemas';
import { parseDurationStringToMilliseconds } from 'technical/time-utils';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import { NumberRangeValue } from 'ui/form/number-range-input';
import {
  BUILD_COLOR,
  BUILD_COLOR_SECONDARY,
  EXCAVATION_COLOR,
  EXCAVATION_COLOR_SECONDARY,
} from 'ui/theme/colors';

interface ExcavationBuildTimeGraphProps {
  excavationBuildTimeGraphData: GetExcavationAndBuildingDurationResponseDto['graphData'];
}
export const TIME_DAY_HOUR_MINUTE_FORMAT = 'DD:HH:mm';

const hoursToMilliseconds = (hours: number | null | undefined) => {
  if (isUndefinedOrNull(hours)) {
    return undefined;
  }

  return hours * 3.6 * 10 ** 6;
};

const renderDurationString = (
  { datum, yKey, xKey }: AgBarSeriesTooltipRendererParams,
  t: TFunction,
) => {
  return {
    heading: t(
      'productionAndPerformance.excavationBuildTime.graph.tooltip.heading',
      {
        ring: datum[xKey],
      },
    ),
    data: [{ label: '', value: parseAndFormatRingDuration(datum[yKey]) }],
  } satisfies AgTooltipRendererResult;
};

const excavationBuildTimeSeriesRealDurationsOptions = (
  t: TFunction,
  visibility: LegendFilter,
) =>
  [
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'realExcavationDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.realExcavationDuration',
      ),
      stackGroup: 'Real',
      tooltip: {
        renderer: (params) => renderDurationString(params, t),
      },
      visible: visibility.realExcavationDuration,
      fill: EXCAVATION_COLOR,
    },
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'realBuildDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.realBuildDuration',
      ),
      stackGroup: 'Real',
      tooltip: {
        renderer: (params) => renderDurationString(params, t),
      },
      visible: visibility.realBuildDuration,
      fill: BUILD_COLOR,
    },
  ] satisfies AgCartesianSeriesOptions[];

const excavationBuildTimeSeriesTotalDurationsOptions = (
  t: TFunction,
  visibility: LegendFilter,
) =>
  [
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'totalExcavationDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.totalExcavationDuration',
      ),
      stackGroup: 'Total',
      tooltip: {
        renderer: (params) => renderDurationString(params, t),
      },
      visible: visibility.totalExcavationDuration,
      fill: EXCAVATION_COLOR_SECONDARY,
    },
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'totalBuildDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.totalBuildDuration',
      ),
      stackGroup: 'Total',
      tooltip: {
        renderer: (params) => renderDurationString(params, t),
      },
      visible: visibility.totalBuildDuration,
      fill: BUILD_COLOR_SECONDARY,
    },
  ] satisfies AgCartesianSeriesOptions[];

const excavationBuildTimeAxesOptions = ({
  durationInterval,
}: {
  durationInterval: NumberRangeValue | undefined;
}) =>
  [
    {
      type: 'category',
      position: 'bottom',
    },
    {
      type: 'number',
      position: 'left',
      label: {
        formatter: ({ value }) => parseAndFormatRingDuration(value),
      },
      min: hoursToMilliseconds(durationInterval?.[0]),
      max: hoursToMilliseconds(durationInterval?.[1]),
    },
  ] satisfies AgCartesianAxisOptions[];

const formatGraphDataWithDuration = (
  excavationBuildTimeGraphData: GetExcavationAndBuildingDurationResponseDto['graphData'],
) =>
  excavationBuildTimeGraphData.map(
    ({
      ringNumber,
      totalExcavationDuration,
      realExcavationDuration,
      totalBuildDuration,
      realBuildDuration,
    }) => {
      return {
        ringNumber: ringNumber,
        totalExcavationDuration: parseDurationStringToMilliseconds(
          totalExcavationDuration,
        ),
        realExcavationDuration: parseDurationStringToMilliseconds(
          realExcavationDuration,
        ),
        totalBuildDuration:
          parseDurationStringToMilliseconds(totalBuildDuration),
        realBuildDuration: parseDurationStringToMilliseconds(realBuildDuration),
      };
    },
  );

export const ExcavationBuildTimeGraph = ({
  excavationBuildTimeGraphData,
}: ExcavationBuildTimeGraphProps) => {
  const { ref: chartRef } = useGraphReferenceContext();

  const { t } = useTranslation();
  const filters = useProdAndPerfFilters();
  const showTotalDurations = filters?.showTotalDurations;

  const { legendFilter, setLegendFilter } = useLegendFilter();

  const realDurationsSeries = excavationBuildTimeSeriesRealDurationsOptions(
    t,
    legendFilter,
  );
  const series = realDurationsSeries;

  if (showTotalDurations) {
    const totalDurationsSeries = excavationBuildTimeSeriesTotalDurationsOptions(
      t,
      legendFilter,
    );
    series.push(...totalDurationsSeries);
  }

  const updateVisibility = ({ itemId, visible }: AgSeriesVisibilityChange) => {
    if (itemId && typeof itemId === 'string') {
      setLegendFilter((value) => {
        const updatedVisibility = {
          ...value,
          [itemId]: visible,
        };

        return updatedVisibility;
      });
    }
  };

  const chartOptions = {
    data: formatGraphDataWithDuration(excavationBuildTimeGraphData),
    series,
    axes: excavationBuildTimeAxesOptions({
      durationInterval: filters.durationInterval,
    }),
    listeners: {
      seriesVisibilityChange: updateVisibility,
    },
    zoom: {
      // zoom on where the mouse is
      anchorPointX: 'pointer',
      anchorPointY: 'pointer',
    },
  } satisfies AgChartOptions;

  useEffect(() => {
    const resizeChart = async () => {
      const currentChartOptions = chartRef?.current?.getOptions();
      await chartRef?.current?.updateDelta({
        width: currentChartOptions?.container?.clientWidth,
      });
    };

    addEventListener('resize', resizeChart);

    return () => removeEventListener('resize', resizeChart);
  }, [chartRef]);

  return <AgCharts options={chartOptions} ref={chartRef} />;
};
