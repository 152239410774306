import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { ProdAndPerfFilters } from 'business/production-and-performance/components/prod-and-perf-filters';
import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import { ExcavationBuildTimeGraphContainer } from 'business/production-and-performance/pages/excavation-build-time/components/excavation-build-time-graph-container';
import { GraphReferenceContextProvider } from 'business/production-and-performance/pages/excavation-build-time/providers/graph-reference';
import { LegendFilterProvider } from 'business/production-and-performance/pages/excavation-build-time/providers/legend-filter';
import { ProdAndPerfFiltersProvider } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { FilterCard } from 'ui/filter-card';

import { ExcavationBuildTimeHeader } from './components/excavation-build-time-header';

const ExcavationBuildTimePage = () => {
  const { t } = useTranslation();

  return (
    <GraphReferenceContextProvider>
      <ProdAndPerfFiltersProvider
        initialFilters={{
          filterType: 'date',
          dateRange: [dayjs().subtract(1, 'week'), dayjs()],
          dayMode: 'shift',
          showTotalDurations: false,
          durationInterval: [0, null],
        }}
      >
        <LegendFilterProvider>
          <ProdPerfPageTemplate
            title={t('productionAndPerformance.excavationBuildTime.title')}
            header={<ExcavationBuildTimeHeader />}
            filters={
              <FilterCard>
                <ProdAndPerfFilters
                  dayModeFilterEnabled
                  durationIntervalEnabled
                  showTotalDurationEnabled
                />
              </FilterCard>
            }
            printTitle={t(
              'productionAndPerformance.excavationBuildTime.print.title',
            )}
          >
            <>
              <ExcavationBuildTimeGraphContainer />
            </>
          </ProdPerfPageTemplate>
        </LegendFilterProvider>
      </ProdAndPerfFiltersProvider>
    </GraphReferenceContextProvider>
  );
};

export default ExcavationBuildTimePage;
