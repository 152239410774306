import { App, Form, Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { parseRingTimeInputToDateWithoutSeconds } from 'business/production-and-performance/pages/ring-table/services/ring-time';
import {
  GetRingsQuery,
  useUpdateParameterRingMutation,
} from 'generated/graphql';
import apolloClient from 'technical/graphql/client';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import {
  parseDurationInputValueToDurationIsoString,
  parseIsoDurationToDurationInputValue,
} from 'ui/form/duration-input/parse-iso-duration';

import { UpdateRingFormValues } from './types';
import { UpdateRingForm } from './update-ring-form';

interface UpdateRingModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  ring: GetRingsQuery['parameter_ring'][number] | null;
}

export const UpdateRingModal: React.FC<UpdateRingModalProps> = ({
  isModalVisible,
  closeModal,
  ring,
}) => {
  const { message } = App.useApp();

  const { t } = useTranslation();
  const [form] = Form.useForm<UpdateRingFormValues>();
  const [updateParameterRingMutation] = useUpdateParameterRingMutation();

  const save = async (row: UpdateRingFormValues) => {
    try {
      await updateParameterRingMutation({
        variables: {
          id: ring?.id,
          updatedFields: {
            ...row,
            excavationStart: row.excavationStart?.toISOString(),
            excavationEnd: row.excavationEnd?.toISOString(),
            buildStart: row.buildStart?.toISOString(),
            buildEnd: row.buildEnd?.toISOString(),
            realExcavationDuration: parseDurationInputValueToDurationIsoString(
              row.realExcavationDuration,
            ),
            realBuildDuration: parseDurationInputValueToDurationIsoString(
              row.realBuildDuration,
            ),
          },
        },
        onCompleted: async () => {
          await apolloClient.reFetchObservableQueries();
          closeModal();
          message.open({
            type: 'success',
            content: t(
              'productionAndPerformance.ring.edit.modal.update.success',
            ),
          });
        },
        onError: () => {
          message.open({
            type: 'error',
            content: t('productionAndPerformance.ring.edit.modal.update.error'),
          });
        },
      });
    } catch (err) {
      message.open({
        type: 'error',
        content: t('productionAndPerformance.ring.edit.modal.update.error'),
      });
    }
  };

  useEffect(() => {
    if (ring) {
      form.setFieldsValue({
        ...ring,
        excavationStart: parseRingTimeInputToDateWithoutSeconds(
          ring.excavationStart,
        ),
        excavationEnd: parseRingTimeInputToDateWithoutSeconds(
          ring.excavationEnd,
        ),

        realExcavationDuration: parseIsoDurationToDurationInputValue(
          ring.realExcavationDuration,
        ),
        buildStart: parseRingTimeInputToDateWithoutSeconds(ring.buildStart),
        buildEnd: parseRingTimeInputToDateWithoutSeconds(ring.buildEnd),
        realBuildDuration: parseIsoDurationToDurationInputValue(
          ring.realBuildDuration,
        ),
      });
    }
  }, [form, ring]);

  return (
    <Modal
      title={t('productionAndPerformance.ring.edit.modal.title', {
        ringNumber: ring?.ringNumber,
      })}
      open={isModalVisible}
      onOk={form.submit}
      onCancel={closeModal}
      destroyOnClose
    >
      {!isUndefinedOrNull(ring) ? (
        <UpdateRingForm form={form} save={save} />
      ) : null}
    </Modal>
  );
};
