import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { PlotterMode } from 'business/data-analysis/constants';
import {
  DataAnalysisControllerGraphByPeriodVariables,
  DataAnalysisControllerGraphByRingVariables,
  useDataAnalysisControllerGraphByPeriod,
  useDataAnalysisControllerGraphByRing,
} from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';

import { useQueryParams } from './use-query-params';

export const useGetGraphValues = (
  queryParams: ReturnType<typeof useQueryParams>,
) => {
  const headers = useRestApiHeaders(Module_Enum.DataAnalysis);

  const {
    mutateAsync: fetchGraphValuesByPeriod,
    isPending: isFetchingGraphValuesByPeriod,
  } = useDataAnalysisControllerGraphByPeriod({});

  const {
    mutateAsync: fetchGraphValuesByRing,
    isPending: isFetchingGraphValuesByRing,
  } = useDataAnalysisControllerGraphByRing();

  const getUpdatedGraphByMode = async (mode: PlotterMode) => {
    if (mode === PlotterMode.TIME_PERIOD) {
      const graphByPeriodResponse = await fetchGraphValuesByPeriod({
        ...queryParams,
        headers,
      } as DataAnalysisControllerGraphByPeriodVariables);
      if (!graphByPeriodResponse) {
        return null;
      }

      return graphByPeriodResponse;
    }

    if (mode === PlotterMode.RING) {
      const graphByRingResponse = await fetchGraphValuesByRing({
        ...queryParams,
        headers,
      } as DataAnalysisControllerGraphByRingVariables);
      if (!graphByRingResponse) {
        return null;
      }

      return graphByRingResponse;
    }

    return null;
  };

  return {
    getUpdatedGraphByMode,
    isFetching: isFetchingGraphValuesByPeriod || isFetchingGraphValuesByRing,
  };
};
