import { createContext, useContext, useMemo } from 'react';

import { getAllowedRoles } from 'business/user/services/authentication';
import { ROLES } from 'business/user/services/config';
import {
  userHasRole,
  userIsDataManager,
} from 'business/user/services/permissions';
import {
  CommonUserFieldsFragment,
  ConstructionSiteFieldsFragment,
  Module_Enum,
  UserPermissionsFieldsFragment,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

import { useAppContext } from './useAppContext';

interface ModulePermissions {
  isConstructionSiteManager: boolean;
  isConstructionSiteFullReader: boolean;
  isConstructionSiteReader: boolean;
  isConstructionSiteUser: boolean;
  hasManagerReadPermission: boolean;
  hasManagerEditPermission: boolean;
  role: ROLES | undefined;
}

export interface PermissionsContext {
  constructionSiteId: string;
  rawPermissions: UserPermissionsFieldsFragment[];
  isDataManager: boolean;
  modules: Record<Module_Enum, ModulePermissions>;
}

const PermissionsContext = createContext<PermissionsContext | null>(null);

const computeUserPermissionsForModule = (
  module: Module_Enum,
  user: CommonUserFieldsFragment,
  constructionSite: ConstructionSiteFieldsFragment,
): ModulePermissions => {
  const modulePermissions = constructionSite.userPermissions.filter(
    (userPermission) => userPermission.module === module,
  );

  if (modulePermissions.length !== 1) {
    return {
      isConstructionSiteManager: false,
      isConstructionSiteFullReader: false,
      isConstructionSiteReader: false,
      isConstructionSiteUser: false,
      hasManagerReadPermission: false,
      hasManagerEditPermission: false,
      role: undefined,
    } satisfies ModulePermissions;
  }

  const userRoles = {
    isConstructionSiteManager: userHasRole(
      ROLES.CONSTRUCTION_SITE_MANAGER,
      constructionSite,
      user,
      module,
    ),
    isConstructionSiteFullReader: userHasRole(
      ROLES.CONSTRUCTION_SITE_FULL_READER,
      constructionSite,
      user,
      module,
    ),
    isConstructionSiteReader: userHasRole(
      ROLES.CONSTRUCTION_SITE_READER,
      constructionSite,
      user,
      module,
    ),
    isConstructionSiteUser: userHasRole(
      ROLES.USER,
      constructionSite,
      user,
      module,
    ),
  };

  return {
    ...userRoles,
    hasManagerReadPermission:
      userRoles.isConstructionSiteManager ||
      userRoles.isConstructionSiteFullReader,
    hasManagerEditPermission: userRoles.isConstructionSiteManager,
    role: modulePermissions[0].permissionType.name as ROLES,
  } satisfies ModulePermissions;
};

export const PermissionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user, currentConstructionSite, constructionSites } = useAppContext();

  const userAllowedRoles = getAllowedRoles();

  const permissions = useMemo(() => {
    if (!user || !constructionSites.length || !currentConstructionSite) {
      return null;
    }

    return {
      constructionSiteId: currentConstructionSite.id,
      rawPermissions: currentConstructionSite.userPermissions,
      isDataManager: userIsDataManager(userAllowedRoles),
      modules: {
        [Module_Enum.ShiftReport]: computeUserPermissionsForModule(
          Module_Enum.ShiftReport,
          user,
          currentConstructionSite,
        ),
        [Module_Enum.DataAnalysis]: computeUserPermissionsForModule(
          Module_Enum.DataAnalysis,
          user,
          currentConstructionSite,
        ),
        [Module_Enum.Backoffice]: computeUserPermissionsForModule(
          Module_Enum.Backoffice,
          user,
          currentConstructionSite,
        ),
        [Module_Enum.ProdPerf]: computeUserPermissionsForModule(
          Module_Enum.ProdPerf,
          user,
          currentConstructionSite,
        ),
        [Module_Enum.Steering]: computeUserPermissionsForModule(
          Module_Enum.Steering,
          user,
          currentConstructionSite,
        ),
      },
    } satisfies PermissionsContext;
  }, [constructionSites, currentConstructionSite, user, userAllowedRoles]);

  logger.info('PermissionsContext', {
    permissions,
  });

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const permissions = useContext(PermissionsContext);

  if (!permissions) {
    const error = new Error(
      'This context can only be called if user has construction site access',
    );
    logger.error(
      'This context can only be called if user has construction site access',
    );

    errorReporting.error(error);
    throw error;
  }

  return permissions;
};

export const getEnabledModuleList = (
  isDataManager: boolean,
  constructionSiteToCompare: ConstructionSiteFieldsFragment,
): Module_Enum[] => {
  if (isDataManager) {
    const constructionSiteEnabledModules =
      constructionSiteToCompare.enabledModules
        ?.map(({ module }) => module)
        .filter((module) => module !== Module_Enum.Backoffice);

    return constructionSiteEnabledModules ?? [];
  }

  const { userPermissions } = constructionSiteToCompare;

  // Filter out the Backoffice module, as it shouldn't count
  const filteredPermissions = userPermissions.filter(
    (permission) => permission.module !== Module_Enum.Backoffice,
  );

  return filteredPermissions.map(({ module }) => module);
};

export const useCheckPermission = (permissions: string[]) => {
  const { currentConstructionSite } = useAppContext();
  const { rawPermissions, isDataManager } = usePermissions();

  if (isDataManager) {
    const constructionSiteEnabledModules =
      currentConstructionSite?.enabledModules
        ?.map(({ module }) => module)
        .filter((module) => module !== Module_Enum.Backoffice);

    const isPermissionEnabledForConstructionSite =
      (constructionSiteEnabledModules || []).filter((x) =>
        permissions.includes(x),
      ).length >= 1;

    if (!isPermissionEnabledForConstructionSite) {
      return false;
    }

    return true;
  }

  const isPermissionPresent = (permissionToFind: string) =>
    !!rawPermissions.find(
      (permission) => permission.module === permissionToFind,
    );

  const permissionCheck = permissions.reduce((previous, current) => {
    return isPermissionPresent(current) || previous;
  }, false);

  return permissionCheck;
};
