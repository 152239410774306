import { Empty, Flex } from 'antd';
import invariant from 'tiny-invariant';

import { useRestApiHeaders } from 'business/contextProviders/useApiHeaders';
import { useAppContext } from 'business/contextProviders/useAppContext';
import { ExcavationBuildTimeAverages } from 'business/production-and-performance/pages/excavation-build-time/components/excavation-build-time-averages';
import { ExcavationBuildTimeGraph } from 'business/production-and-performance/pages/excavation-build-time/components/excavation-build-time-graph';
import moduleStyles from 'business/production-and-performance/production-and-performance.module.scss';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { getFetchRange } from 'business/production-and-performance/services/getFetchRange';
import { useExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration } from 'generated/apiComponents';
import { Module_Enum } from 'generated/graphql';
import Loader from 'ui/loader';

export const ExcavationBuildTimeGraphContainer = () => {
  const { currentConstructionSiteId } = useAppContext();
  const headers = useRestApiHeaders(Module_Enum.ProdPerf);
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const filters = useProdAndPerfFilters();
  const fetchRange = getFetchRange(filters);

  const { data, isLoading } =
    useExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration({
      queryParams: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: filters.dayMode,
        ...fetchRange,
      },
      headers,
    });

  if (isLoading) {
    return <Loader />;
  }

  if (!data || !data.graphData.length) {
    return <Empty />;
  }

  return (
    <Flex vertical gap={24}>
      <ExcavationBuildTimeAverages data={data.averages} />
      <div className={moduleStyles.graphContainer}>
        <ExcavationBuildTimeGraph
          excavationBuildTimeGraphData={data.graphData}
        />
      </div>
    </Flex>
  );
};
