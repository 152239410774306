import { AgChartInstance, AgChartOptions } from 'ag-charts-enterprise';
import {
  PropsWithChildren,
  RefObject,
  createContext,
  createRef,
  useContext,
} from 'react';

type GraphPrinting = {
  ref: RefObject<AgChartInstance<AgChartOptions>> | undefined;
};

const GraphReferenceContext = createContext<GraphPrinting>({
  ref: undefined,
});

export const GraphReferenceContextProvider = ({
  children,
}: PropsWithChildren) => {
  const graphRef = createRef<AgChartInstance<AgChartOptions>>();

  return (
    <GraphReferenceContext.Provider value={{ ref: graphRef }}>
      {children}
    </GraphReferenceContext.Provider>
  );
};

export const useGraphReferenceContext = () => useContext(GraphReferenceContext);
