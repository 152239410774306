import { UploadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { App, Modal, Space, Tooltip, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useTranslation } from 'translations/hooks';

import {
  useGraphqlApiHeaders,
  useRestApiHeaders,
} from 'business/contextProviders/useApiHeaders';
import { usePlanningControllerImportPlanning } from 'generated/apiComponents';
import {
  Module_Enum,
  useDeleteProjectionMutation,
  useGetRingProjectionCountQuery,
} from 'generated/graphql';
import { checkFileLength } from 'technical/file/check-file-length';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import Button from 'ui/button';

interface ManagerControlsProps {
  constructionSiteId: string;
}

const maxFileSizeMB = 5;
const maxFileSizeInBytes = maxFileSizeMB * 1024 * 1024; // 5 MB in bytes

const checkXLSFile = (file: RcFile, onError: () => void) => {
  const isExcelMimeType =
    file.type === 'application/vnd.ms-excel' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const isExcelExtension =
    file.name.endsWith('.xls') || file.name.endsWith('.xlsx');

  const isExcel = isExcelMimeType && isExcelExtension;

  if (!isExcel && onError) {
    onError();
  }

  return isExcel || Upload.LIST_IGNORE;
};

export const ManagerControls: React.FC<ManagerControlsProps> = ({
  constructionSiteId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const graphqlHeaders = useGraphqlApiHeaders(Module_Enum.ProdPerf);
  const restHeaders = useRestApiHeaders(Module_Enum.ProdPerf);

  const {
    data: projectionCountData,
    loading: countIsLoading,
    refetch,
  } = useGetRingProjectionCountQuery({
    variables: { constructionSiteId },
    context: {
      headers: graphqlHeaders,
    },
  });

  const { mutateAsync: importPlanning, isPending: isImportPending } =
    usePlanningControllerImportPlanning({
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['production-and-performance', 'planning'],
        });

        await refetch();
      },
    });

  const [deleteProjection, { loading: isDeleteProjectionLoading }] =
    useDeleteProjectionMutation({
      onCompleted: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['production-and-performance', 'planning'],
        });

        await refetch();
      },
    });

  const handleImport = async ({ file }: { file: string | Blob | RcFile }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('constructionSiteId', constructionSiteId);

    await importPlanning(
      {
        headers: {
          ...restHeaders,
          'Content-Type': 'multipart/form-data',
        },
        // @ts-expect-error we expect the generated type to not recognise formData
        body: formData,
      },
      {
        onSuccess: () =>
          message.success(
            t('productionAndPerformance.planning.import.success'),
          ),
        onError: (error) => {
          message.error(
            t('productionAndPerformance.planning.import.error', {
              // @ts-expect-error Front generated conflict error type do not match backend type
              context: error.stack?.error,
            }),
            0, // force manual close
          );
        },
      },
    );
  };

  const constructionSiteHasProjection =
    !isUndefinedOrNull(projectionCountData?.ringProjection?.aggregate?.count) &&
    projectionCountData?.ringProjection?.aggregate?.count > 0;

  return (
    <Space>
      <Button
        danger
        loading={isDeleteProjectionLoading}
        disabled={!constructionSiteHasProjection || countIsLoading}
        onClick={() => {
          Modal.confirm({
            title: t('productionAndPerformance.planning.delete.confirm.title'),
            content: t(
              'productionAndPerformance.planning.delete.confirm.content',
            ),
            okText: t(
              'productionAndPerformance.planning.delete.confirm.confirmDelete',
            ),
            cancelText: t(
              'productionAndPerformance.planning.delete.confirm.cancel',
            ),
            closable: false,
            okButtonProps: { danger: true },
            onOk: async () => {
              await deleteProjection({
                variables: {
                  constructionSiteId,
                },
                context: {
                  headers: graphqlHeaders,
                },
              });
            },
            footer: (_, { OkBtn, CancelBtn }) => (
              <Space>
                <CancelBtn />
                <OkBtn />
              </Space>
            ),
          });
        }}
      >
        {t('productionAndPerformance.planning.delete.cta')}
      </Button>
      <Tooltip
        title={t('productionAndPerformance.planning.import.info')}
        placement="bottom"
      >
        <Upload
          maxCount={1}
          disabled={isImportPending}
          multiple={false}
          showUploadList={false}
          accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          beforeUpload={(file) => {
            return [
              checkFileLength(
                file,
                () => {
                  message.error(
                    t(
                      'productionAndPerformance.planning.import.error_INVALID_FILE_SIZE',
                    ),
                  );
                },
                maxFileSizeInBytes,
              ),
              checkXLSFile(file, () => {
                message.error(
                  t(
                    'productionAndPerformance.planning.import.error_INVALID_FILE_TYPE',
                  ),
                );
              }),
            ].every((result) => result === true);
          }}
          customRequest={handleImport}
        >
          <Button
            type="primary"
            icon={<UploadOutlined />}
            loading={isImportPending}
          >
            {t('productionAndPerformance.planning.import.label')}
          </Button>
        </Upload>
      </Tooltip>
    </Space>
  );
};
